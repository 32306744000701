import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Hello I am Stella</h1>
      <h3>My Page is coming soon...</h3>
      <p>Can't wait to share my journey with you!</p>
    </div>
  );
}

export default App;
